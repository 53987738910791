/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $(".copy-address").on("click", function(e){



          var textBox = document.getElementById("addressCopy");
          textBox.select();
          document.execCommand("copy");

          alert("Address copied!");
        });

        $(".nav-toggle, .overlay-nav").on("click", function(e){

          e.preventDefault();

          $(".mobile-navigation-container, .overlay-nav").toggleClass("show");

        });

        $(".notification-toggle, .overlay-nav-notifications").on("click", function(e){

          e.preventDefault();

          $(".notification-container, .overlay-nav-notifications").toggleClass("show");

        });


        $(".tooltips").tooltip();

        $(".app-link").on("click", function(e){

          e.preventDefault();

          var destination = $(this).attr("href");

          $(".overlay-loading").addClass("show");

         setTimeout(
          function() 
          {

            window.location.replace(destination);
            //do something special
          }, 1000);

        });

    

        $(".eye-show").on("click", function(e){

          e.preventDefault();

          $(this).toggleClass("show-password");
          var inputField = $(this).data("field");

          if ($(this).hasClass('show-password')) {
            $("#"+inputField).attr("type", "text");
            $(this).html("hide <span class='fa fa-eye-slash'></span>");
            
          } 
          else {
            $("#"+inputField).attr("type", "password");
            $(this).html("show <span class='fa fa-eye'></span>");
          }
        });


        

      


        $(".show-transaction").on("click", function(e){


          e.preventDefault();

            $(this).next(".overlay-blurred").toggleClass("show");

        });


        $(".close-transaction").on("click", function(e){


          e.preventDefault();

            $(".overlay-blurred.show").removeClass("show");

        });



        
      


      $(".max-input").on("click", function(e){


        var balance = $(this).data("balance");
        var rate = $(".max-input").data("rate");


        $("#sendAmount").val(balance);
        $(".rate-value").html(((balance * rate)).toFixed(2));


      });



      $("#sendAmount").on("keyup change input blur", function(e){


        var rate = $(".max-input").data("rate");
        var amount = $(this).val();


        $(".rate-value").html(((amount * rate)).toFixed(2));
        

      });



  
      }



    },
    // Home page
    'step_1': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        $("#emailAddress").on('keyup blur input keydown change', function(){

             var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

             var email = $(this).val();

             if(emailPattern.test(email)){
                $("#emailAddress").addClass("valid");
                $("#emailAddress").removeClass("error");
                $("#emailAddress").parent().find(".error-message").remove();

             }else{
                $("#emailAddress").removeClass("valid");
                $("#emailAddress").addClass("error");

             }


        });

        $("#phoneNumber").on('keyup blur input keydown change', function(){

             var phonePattern = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

             var phone = $(this).val();

             if(phonePattern.test(phone)){
                $("#phoneNumber").addClass("valid");
                $("#phoneNumber").removeClass("error");
                $("#phoneNumber").parent().parent().find(".error-message").remove();

             }else{
                $("#phoneNumber").removeClass("valid");
                $("#phoneNumber").addClass("error");

             }


        });



        $("#pw1").on('keyup blur input', function(){

          var number = /([0-9])/;
          var alphabets = /([A-Z])/;
          var special_characters = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;
          var password = $('#pw1').val().trim();
          var score = 0;

            if (password.length >= 8) {
              
              score = score + 5;
              
            }

            if (password.length >= 13) {
              
              score = score + 10;
              
            }
         
            if (password.match(number)) {
              score = score + 5;
            }

            if (password.match(special_characters)) {
               score = score + 10;
            }

            if (password.match(alphabets) ) {
              
               score = score + 5;

            }

            if(score >= 5 && score <= 15 ){
                $(".pw-strength").find("span").text("Poor");
            }


            if(score > 15 && score <= 20 ){
                $(".pw-strength").find("span").text("Average");
                $(".pw-strength").find("span").removeClass("text-danger");
                $(".pw-strength").find("span").addClass("text-warning");
            }


            if(score > 20 ){
                $(".pw-strength").find("span").text("Great");
                $(".pw-strength").find("span").removeClass("text-danger");
                $(".pw-strength").find("span").removeClass("text-warning");
                $(".pw-strength").find("span").addClass("text-success");

                $("#pw1").addClass("valid");
            }

          



        });


        $("#firstName, #lastName").on('keyup blur input', function(){

         
          var name = $(this).val();

            if (name.length >= 2) {
              
              $(this).removeClass("error");
              $(this).addClass("valid");
              
            }else{
               $(this).removeClass("valid");
              $(this).addClass("error");
            }
        });

        $("#securityPhrase").on('keyup blur input', function(){

         
          var phrase = $(this).val();

            if (phrase.length >= 4) {
              
              $(this).removeClass("error");
              $(this).parent().find(".error-message").remove();
              $(this).addClass("valid");
              
            }else{
              $(this).parent().find(".error-message").remove();
              $(this).removeClass("valid");
              $(this).addClass("error");
              $(this).parent().append("<span class='text-danger error-message'>Minimum of 4 characters. </span>");
            }
        });


        $("#termsCheck").on('change', function(){

         
         

            if ($(this).is(":checked")) {
              
              $(this).removeClass("error");
              $(this).addClass("valid");
              
            }else{
              $(this).removeClass("valid");
              $(this).addClass("error");
              $(this).parent().parent().append("<span class='text-danger error-message'>You must to agree to our terms.</span>");
            }
        });


        $("#pw2").on('keyup blur input', function() {
          var originalPassword = $('#pw1').val().trim();
          var password = $('#pw2').val().trim();

          

            if (password === originalPassword) {
              $("#pw2").addClass("valid");
              $("#pw2").parent().find(".error-message").remove();

            }else{
                $("#pw2").parent().find(".error-message").remove();
                $("#pw2").removeClass("valid");
                $("#pw2").parent().append("<span class='text-danger error-message'>Passwords do not match.</span>");
            }
      });        


        $(".btn-submit").on("click", function(e){

            e.preventDefault();

            var validCount = 0;

           if($("#firstName").hasClass("valid")){
              validCount++;
           }else{
              $("#firstName").addClass("error");
              $("#firstName").parent().append("<span class='text-danger error-message'>Your name is required.</span>");
           }


           if($("#lastName").hasClass("valid")){
              validCount++;
           }else{
              $("#lastName").addClass("error");
              $("#lastName").parent().append("<span class='text-danger error-message'>Your last name is required.</span>");
           }


           if($("#emailAddress").hasClass("valid")){
              validCount++;
           }else{
              $("#emailAddress").addClass("error");
              $("#emailAddress").parent().append("<span class='text-danger error-message'>Not a valid email address.</span>");
           }


           if($("#phoneNumber").hasClass("valid")){
              validCount++;
           }else{
              $("#phoneNumber").addClass("error");
              $("#phoneNumber").parent().parent().append("<span class='text-danger error-message'>Not a valid phone number.</span>");
           }

           if($("#pw1").hasClass("valid")){
              validCount++;
           }else{
              $("#pw1").addClass("error");
              $("#pw1").parent().append("<span class='text-danger error-message'>Not a strong enough password.</span>");
           }

           if($("#pw2").hasClass("valid")){
              validCount++;
           }else{
              $("#pw2").addClass("error");
              $("#pw2").parent().append("<span class='text-danger error-message'>Password does not match.</span>");
           }

           if($("#securityPhrase").hasClass("valid")){
              validCount++;
           }else{
              $("#securityPhrase").addClass("error");
              $("#securityPhrase").parent().append("<span class='text-danger error-message'>A security phrase is required.</span>");
           }

           if($("#termsCheck").is(":checked")){
              validCount++;
           }else{
              $("#termsCheck").addClass("error");
              $("#termsCheck").parent().parent().append("<span class='text-danger error-message'>You must agree to our terms of service</span>");
           }

           if(validCount > 6){

                $("#signupForm")[0].submit();

           } else {
                return false;
           }

        });
      }
    },
    // Home page
    'step_2': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
       

        $(".btn-verify-phone").on("click", function(e){

            e.preventDefault();

            if($("#vcode").val() === $("#verifyInput").val()){
                    $("#verifyForm")[0].submit();
            }else{
                    $("#verifyInput").parent().append("<span class='text-danger error-message'>Invalid Code. Try again.</span>");
                    return false;
            }

        });


      }
    },
    // Home page
    'step_3': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

                    $("#mainSeed").html("<span class='fa fa-spinner fa-spin-pulse' id='loader'></span> ");

                    var formURL = $("#seedURL").val();

                     $.ajax({
                        url: formURL,
                        method: 'post',
                        success: function(response){
                          
                          var dataResponse = JSON.parse(response);
                          var words = dataResponse.mnemonic;
                          var xpub = dataResponse.xpub;
                          var wordCount = 1;
                          var randomWord = Math.floor(Math.random() * (21 - 1 + 1) + 1);
                          var randomWordCount = Math.floor(Math.random() * (21 - 1 + 1) + 1);
                          var selectedWords = [];
                          var randomWordList = [];

                          $(".seed-container").find("#loader").fadeOut(500);

                          $.each(words, function(key, item) {
                              var word = "<span class='word-pill'>"+wordCount+". "+item+"</span>";
                              $(word).appendTo('#mainSeed');
                              

                              if(wordCount === randomWord){
                                  $("#chosenWord").text(wordCount +". "+ item );
                                  var chosenWord = item;
                              }

                              if(wordCount === (randomWord + 1)){
                                  selectedWords.push(item);
                              }

                              if(wordCount === (randomWord + 2)){
                                  selectedWords.push(item);
                              }

                              if(wordCount === (randomWord + 3)){
                                  selectedWords.push(item);
                              }


                              if(selectedWords.length <= 7 && selectedWords.indexOf(item) < 0 && chosenWord !== item)  {
                                selectedWords.push(item);
                              }

                              wordCount++;
                          });


                          function shuffle(arr){
                              for(var j, x, i = arr.length; i; j = parseInt(Math.random() * i), x = arr[--i], arr[i] = arr[j], arr[j] = x);
                              return arr;
                       
                          }

                          var randomWordsShuffled = shuffle(selectedWords);

                          $.each(randomWordsShuffled, function(key, item) {
                              var word = "<span class='word-pill'>"+item+"</span>";
                              $(word).appendTo('#randomSeed');
                              
                            });

                        

                          const form = document.getElementById('signupForm');
                          const hiddenInput = document.createElement('input');
                          hiddenInput.setAttribute('type', 'hidden');
                          hiddenInput.setAttribute('name', 'mnemo');
                          hiddenInput.setAttribute('value', words);
                          form.appendChild(hiddenInput);


                          const hiddenInputXpub = document.createElement('input');
                          hiddenInputXpub.setAttribute('type', 'hidden');
                          hiddenInputXpub.setAttribute('name', 'xpub');
                          hiddenInputXpub.setAttribute('value', xpub);
                          form.appendChild(hiddenInputXpub);
                           

                           $(".btn-submit-account").attr("disabled", false);

                        },
                        error: function(response){
                            $(".seed-container").html("<p>There was an error. Please try again in 30 minutes.</p>");
                        }
                    });


                     $(".btn-submit-account").on("click", function(e){
                            e.preventDefault();

                            $("#signupForm")[0].submit();
                     });
      }
    },

    // Home page
    'settings': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
       

             $(".show-seed").on("click", function(e){


              e.preventDefault();


               // JavaScript to be fired on the home page, after the init JS
               var formURL = $("#xur").val();
               var formData = $("#mpub").val();
               var wordCount = 1;


               if($(this).hasClass("visible")){
                  $("#hiddenSeed").html("");
                  $("#hiddenSeed").slideUp(500);
                  $(this).text("Show Phrase");
                  $(this).removeClass("visible");
               }else{



               $.ajax({
                  url: formURL,
                  data: {mpub: formData},
                  method: 'post',
                  success: function(response){
                    
                    var dataResponse = JSON.parse(response);
                    var words = dataResponse.split(',');

                     $.each(words, function(key, item) {
                        var word = "<span class='word-pill'>"+item+"</span> ";
                        $(word).appendTo('#hiddenSeed');
                        

                        wordCount++;
                    });

                     $("#hiddenSeed").slideDown(500);
                     $(".show-seed").addClass("visible");
                     $(".show-seed").text("Hide Phrase");

                  }
                });


                }

              });


      }
    },
    // Home page
    'verify': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

            $(".btn-verify").on("click", function(e){


                e.preventDefault();

                if($("#vcode").val() === $("#verifyInput").val()){
                        $("#verifyForm")[0].submit();
                }else{
                        $("#verifyInput").parent().append("<span class='text-danger error-message'>Invalid Code. Try again.</span>");
                        return false;
                }
                
            });
        
            

            


            var timer2 = "59";
            var interval = setInterval(function() {


           
            //by parsing integer, I avoid all extra string processing
     
            var seconds = parseInt(timer2, 10);
            --seconds;
            
            seconds = (seconds < 0) ? 59 : seconds;
            seconds = (seconds < 10) ? '0' + seconds : seconds;
            //minutes = (minutes < 10) ?  minutes : minutes;
            $('.reset-timer').html(seconds + 's');
           
            //check if both minutes and seconds are 0
            timer2 = seconds;
            if (seconds <= 0) timer2 = "59";
            

          }, 1000);

      }
    },
    // Home page
    'wallet': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $("#coinRateSlider").slick({
   
            infinite: true,
            arrows: false,
            
            swipe: true,
            dots: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: false,
                  slidesToShow: 2
                }
              }
            ]
            
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'market': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {

        $("#newsCarousel").slick({
   
            infinite: true,
            arrows: true,
            prevArrow: "<a href='#' class='slick-prev-small'><span class='fa fa-caret-left'></span></a>",
            nextArrow: "<a href='#' class='slick-next-small'><span class='fa fa-caret-right'></span></a>",
            swipe: true,
            dots: true,
            autoplay: true,
            autoplaySpeed: 10000,
            slidesToShow: 1
           
            
        });


        

              $(".coin-market-item").on("click", function(e){

          $(this).next("div.coin-details").slideToggle(500);

        });


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
